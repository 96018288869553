<div
  class="h-full"
  ngFileDrop
  [options]="fileUploader.options"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="fileUploader.uploadInput"
  [ngClass]="{ 'Media-dialog__content--drop-over': fileUploader.DRAG_OVER }"
>
  <mat-sidenav-container>
    <mat-sidenav class="Media-dialog__details" #fileDetails position="end" mode="side" opened="true">
      @if (selectedItems.size < 1 && !UPLOAD_MODE) {
        <div class="Media-dialog__details__noitem">
          {{ 'MEDIA.CHOOSE_FILE_FOLDER' | translate }}
        </div>
      }
      <aw-file-details [hidden]="selectedItems.size <= 0 || UPLOAD_MODE" [items]="selectedItemsArray" [options]="data"> </aw-file-details>
      <aw-file-uploader
        #fileUploader
        [hidden]="!UPLOAD_MODE"
        [uploadParams]="activeLibrary?.config.uploadParams"
        [bucket]="activeBucket"
        (uploadFinished)="onUploadFinished($event)"
      ></aw-file-uploader>
    </mat-sidenav>
    <mat-sidenav-content style="min-height: 100%">
      <mat-tab-group
        #tabGroup
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="Media-dialog__tabs"
        [selectedIndex]="defaultTabIndex"
        (selectedTabChange)="onTabChange($event)"
      >
        @if (data.options.showDefaultLibrary) {
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="flex items-center gap-2">
                <mat-icon>cloud_queue</mat-icon> <span>{{ data.options.mainUnit.title }}</span>
              </div>
            </ng-template>
            <aw-file-library
              #library
              [unit]="data.options.mainUnit"
              [options]="data"
              [mediaDialogService]="mediaDialogService"
              (itemsSelected)="onItemsSelect($event)"
              (bucketEntered)="onBucketEnter($event, activeLibrary)"
            ></aw-file-library>
          </mat-tab>
        }
        @if (data.options.unit && data.options.unit.uuid !== data.options.mainUnit.uuid) {
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="flex items-center gap-2">
                <mat-icon>home</mat-icon> <span>{{ data.options.unit.title }}</span>
              </div>
            </ng-template>
            <aw-file-library
              #library
              [unit]="data.options.unit"
              [options]="data"
              [mediaDialogService]="mediaDialogService"
              (itemsSelected)="onItemsSelect($event)"
              (bucketEntered)="onBucketEnter($event, activeLibrary)"
            ></aw-file-library>
          </mat-tab>
        }

        @if (data.options.showMyLibrary) {
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="flex items-center gap-2">
                <mat-icon>cloud_circle</mat-icon> <span>{{ 'MEDIA.MY_FILES' | translate }}</span>
              </div>
            </ng-template>
            <aw-file-library
              mode="client"
              [client]="data.options.myLibraryClient"
              #library
              [options]="data"
              [unit]="data.options.mainUnit"
              [mediaDialogService]="mediaDialogService"
              (itemsSelected)="onItemsSelect($event)"
              (bucketEntered)="onBucketEnter($event, activeLibrary)"
            >
            </aw-file-library>
          </mat-tab>
        }
      </mat-tab-group>
      @if ((hasUploadPermission$ | async) || activeLibrary?.mode === 'client') {
        <button mat-fab color="primary" class="Media-dialog__action-button" [matMenuTriggerFor]="actionMenu">
          <mat-icon aria-label="Display action menu">add</mat-icon>
        </button>
      }
      <mat-menu #actionMenu="matMenu" yPosition="above" xPosition="before" [overlapTrigger]="false">
        @if (data.options.canUploadFile) {
          <button mat-menu-item (click)="toggleUploadMode()" [disabled]="UPLOAD_MODE">
            <mat-icon>cloud_upload</mat-icon> {{ 'MEDIA.UPLOAD_FILES' | translate }}
          </button>
        }
        @if (data.options.canCreateBucket) {
          <button mat-menu-item (click)="showNewBucketDialog()">
            <mat-icon>create_new_folder</mat-icon> {{ 'MEDIA.CREATE_FOLDER' | translate }}
          </button>
        }
      </mat-menu>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
