<div class="Media-dialog Dialog">
  <mat-toolbar color="primary" class="Dialog__header">
    <h2 class="Dialog__title" mat-dialog-title>
      <mat-icon>perm_media</mat-icon>
      <!-- {{ 'COMMON.MEDIA' | translate }} -->
    </h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="Media-dialog__content Dialog__content" mat-dialog-content>
    <aw-media-main [data]="data"></aw-media-main>
  </div>
</div>
